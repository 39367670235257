.maroon-text,
.maroon-text > a,
.maroon-text > a:hover,
.maroon-text > a:active {
  color: #c93720 !important;
}

.black-text,
.black-text > a,
.black-text > a:hover,
.black-text > a:active {
  color: black !important;
}

.operations-actions {
  display: flex;
  justify-content: left;
}

.operations-actions > div:not(:last-child) {
  margin-right: 20px;
}
