.fulfiller-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* .ellipsis-fulfiller-name {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
} */

.active-for-4-months {
  font-size: 12px;
  line-height: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.email-fulfiller {
  color: #0088a9 !important;
  font-size: 12px;
  line-height: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.performance-left-div {
  width: 200px !important;
  height: 100%;
  border-right: 1px solid #e1e6eb;
}

.performance-left-div-top {
  width: 200px !important;
  padding: 25px;
  word-wrap: break-word;
  margin-bottom: 40px;
}

.performance-right-div {
  width: calc(100% - 200px);
}

.sub-nav-item-vertical {
  margin: 0 !important;
  padding: 15px 20px !important;
  color: #0088a9 !important;
  width: 100%;
  border-bottom: 1px solid #e1e6eb !important;
  text-align: left !important;
}
.nav-stacked > li + li {
  margin-top: 0;
}

.fulfiller-logo {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
  margin-left: 10px;
}
