.card-base {
  background-color: #eff3f5 !important;
  margin: 15px;
  max-width: 400px;
}

.card-heading {
  font-weight: 500;
  margin-top: 0;
}

.card-content {
  display: block;
}

@media screen and (min-width: 480px) {
  .card-content {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }
}

.grey-text {
  color: #a8b6c4;
}

.maroon-text {
  color: #c93720;
}
