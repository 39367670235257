body {
  color: #3a414c !important;
  font-family: 'Open Sans';
  /* temp solution for scroll issue because of tooltip */
  /* height: 100vh; */
  /* overflow: hidden; */
}

/* override for react table right border jumping towards left */
.ReactTable > .rt-table {
  overflow: scroll;
}

.clickable {
  cursor: pointer !important;
}
.clickable > * {
  cursor: pointer !important;
}

.flex-parent {
  display: flex;
}

.flex-parent-center {
  display: flex;
  align-items: center;
}

.main-heading {
  height: 26px;
  font-size: 19px;
  line-height: 26px;
}

.sub-heading {
  height: 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.filters-link {
  color: #0088a9;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;
  margin-left: auto;
  padding-right: 10px;
}

.performance-operations-div {
  padding: 20px;
  width: 100%;
}

.sub-nav-bar {
  width: 100% !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #e1e6eb !important;
}

.sub-nav-item {
  margin: 0 !important;
  padding: 15px 20px !important;
  color: #0088a9 !important;
  min-width: 120px;
}

/* overriding */
.drawer-header {
  padding: 14px 20px !important;
}

.width-full {
  width: 100%;
}

.filters-flex-container {
  display: flex;
  align-items: left;
  flex-direction: row;
}

.filters-flex-item-left {
  width: 100px;
  margin-right: 5px;
}

.filters-flex-item-right {
  margin-left: auto;
  width: 130px;
}

.filters-flex-item-right > button,
.filters-flex-item-left > button {
  width: 100%;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 1200px) {
  .filters-flex-container {
    flex-direction: column;
  }
  .filters-flex-item-left {
    width: 100%;
    margin-right: 0;
  }
  .filters-flex-item-right {
    width: 100%;
    margin-left: 0;
  }
}
